

/* We set the size of the picture */
.product-list .product-image {
    height:250px!important;
    width: 200px;
    padding-top:5px;
    padding-bottom:5px;
    margin-right: 30px;
    flex: 0 0 auto;
}

/* We set the size of the picture */
.product-list .product-image > a {
    height:100%;
}

/* We set the size of the picture */
.product-list .product-image img {
    width: auto;
    height: 100%;
    margin-left:auto;
    margin-right:auto;
}

/* We set shadow and margin for product list item */
.product-list {
    margin: 0 0 40px 0;
    padding: 0;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

/* We ensure that picture is verticaly aligned with content */
.product-list .grid-inner {
    display:flex;
    align-items: center;
}

/* We ensure that picture and content wrap for mobile */
/* picture takes full width and with small margin bottom */
@media (max-width: 767.98px) {
.product-list .product-image {
        width: 100%;
        margin-bottom: 20px;
}
.product-list .grid-inner {
      flex-wrap: wrap;
}
}

/* We display the content next to the picture */
.product-list .product-desc {
    padding-top:10px;
    padding-right:10px;
    padding-bottom: 0px;

    flex: 0 1 auto;
    align-self: stretch;

    display: flex;
    flex-direction: column;
    position:relative;
}
.product-list .product-desc .flags {
    position:absolute;
    right:0px;
    top:0px;
}
.product-list .product-desc .flags img {
    height:80px;
}
@media (max-width: 767.98px) {
.product-list .product-desc {
      padding-left:10px;
      width:100%;
}
}

/* We reduce description margin and ensure it takes possible space */
.product-list .product-desc p {
      flex-grow: 1;
      margin-bottom: 10px;
}

/* We hide the product description on mobile device */
@media (max-width: 767.98px) {
.product-list .product-desc p {
        display:none;
}
}

/* We set flex and correct wrapping to full buttons bar and add to cart section */
.product-list .buttons,
.product-list .buttons .add-to-cart {
    display:flex;
    flex-wrap: wrap;
}

/* We set margins for details button and add to cart sectino */
.product-list .buttons .view-details,
.product-list .buttons .add-to-cart {
    margin:0px 30px 10px 0px;
}



.sticky-cart {
    position:-webkit-sticky;
    position:sticky;
    bottom:0px;
    border-top: 2px solid var(--secondary-color);
    background-color: var(--content-background-color);
    z-index: 50;
}
.sticky-cart.opened .summary {
    padding-bottom: 10px;
}
.sticky-cart .summary .container-md {
    display:flex;
    flex-wrap: wrap;
}
.sticky-cart .summary .title, .sticky-cart .summary .total {
    text-transform: uppercase;
    line-height: 34px;
    font-weight: 600;
}
.sticky-cart .summary .title a.details i:first-child {
    margin-right:10px;
}
.sticky-cart .summary .title a.details i:last-child {
    margin-left:10px;
    transition: 0.3s ease all;
}
.sticky-cart.opened .summary .title a.details i:last-child {
    transform: rotate(180deg);
}
.sticky-cart .summary .total {
    flex-grow:1;
    text-align: center;
}
@media (max-width: 575.98px) {
.sticky-cart .summary {
        padding-bottom: 10px;
}
.sticky-cart .summary .title {
        flex-grow: 1;
}
.sticky-cart .summary .total {
        flex-grow: 0;
}
.sticky-cart .summary .button {
        width:100%;
}
}
.sticky-cart .details .empty {
    padding:10px;
    text-align: center;
}
.sticky-cart .details .table th {
    border-top:0px;
}



@media (max-width: 991.98px) {
  /* We add border bottom and space to side bar when we wrap */
.sidebar.col-lg-3 {
    border-bottom: 1px solid #EEE;
    padding-bottom: 20px;
}
}
.widget_links li.selected > a {
  color: var(--primary-color);
}

